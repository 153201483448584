import React from "react";
import {
  AboutSection,
  AwardsSection,
  ContactSection,
  HeroSection,
  InterestsSection,
  Page,
  Seo,
} from "portfolio-theme";

export default function IndexPage() {
  return (
    <>
      <Seo title="Federico Zarantonello · Home" />
      <Page>
        <HeroSection sectionId="hero" />
        <AboutSection sectionId="about" />
        <InterestsSection sectionId="interests" heading="Interests & Passions" />
        <AwardsSection sectionId="awards" heading="Awards & Achievements" />
        <ContactSection sectionId="contact" heading="Contact me" />
      </Page>
    </>
  );
}
